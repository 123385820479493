import { DestroyRef, effect, inject, InjectionToken, signal, Signal } from '@angular/core';
import { isNotNullish, isNullish, Nullish } from '../ts.helpers';

function toString<V>(value: V): string {
  return JSON.stringify(value);
}

function transform<V>(value: string | null): Nullish<V> {
  if (!value) return null;
  try {
    return JSON.parse(value);
  } catch (e) {
    console.log('unable transform value', e);
    return null;
  }
}

export function storageSignal<V>(key: string, initialValue: V): Signal<V> {
  const destroyRef = inject(DestroyRef);
  const stored: Nullish<V> = transform(localStorage.getItem(key));

  const currentSignal = signal(stored ?? initialValue);

  if (isNullish(stored)) {
    localStorage.setItem(key, toString(initialValue));
  }

  effect(() => {
    const value = currentSignal();
    localStorage.setItem(key, toString(value));
  });

  const listener = () => {
    const value: Nullish<V> = transform(localStorage.getItem(key));
    if (isNotNullish(value)) currentSignal.set(value);
  };

  window.addEventListener('storage', listener);
  destroyRef.onDestroy(() => {
    window.removeEventListener('storage', listener);
  });

  return currentSignal;
}

export const STORAGE_SIGNAL_FACTORY = new InjectionToken<typeof storageSignal>(
  'STORAGE_SIGNAL_FACTORY'
);
