import { inject, Injectable, signal } from '@angular/core';
import { User, UserApiService } from '../../api/user-api.service';
import { waitForSignal } from '../../shared/helpers/wait';
import { finalize } from 'rxjs/operators';
import { catchError, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserStore {
  private readonly userApi = inject(UserApiService);

  readonly user = signal<User | null>(null);
  readonly isLoading = signal(false);
  readonly loadingError = signal<Error | null>(null);

  async loadUser(farce = false): Promise<User | null> {
    this.load();
    if (this.user() && !farce) {
      return this.user();
    }
    await waitForSignal(this.isLoading, false);
    if (this.loadingError()) throw this.loadingError();
    return this.user();
  }

  private load() {
    this.isLoading.set(true);
    this.loadingError.set(null);
    this.userApi
      .get()
      .pipe(
        finalize(() => this.isLoading.set(false)),
        catchError(e => {
          this.loadingError.set(e);
          return throwError(() => e);
        })
      )
      .subscribe(user => {
        this.user.set(user);
      });
  }
}
