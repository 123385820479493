import { effect, inject, Injectable, signal } from '@angular/core';
import {
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
  RouterEvent,
} from '@angular/router';
import { filter } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { StorageService } from './storage.service';

@UntilDestroy()
@Injectable({ providedIn: 'root' })
export class ClearUserService {
  private readonly router = inject(Router);
  private readonly storage = inject(StorageService);

  readonly isNavigating = signal(true);
  readonly needClear = signal(false);

  constructor() {
    this.router.events
      .pipe(
        filter(e => e instanceof RouterEvent),
        untilDestroyed(this)
      )
      .subscribe((event: RouterEvent) => {
        if (this.navigationStart(event)) {
          this.isNavigating.set(true);
        }

        if (this.navigationOther(event)) {
          this.isNavigating.set(false);
        }
      });

    effect(() => {
      if (this.needClear() && !this.isNavigating()) {
        this.storage.clear();
        window.location.reload();
      }
    });
  }

  clear() {
    this.needClear.set(true);
  }

  private navigationStart(e: RouterEvent): boolean {
    return e instanceof NavigationStart;
  }

  private navigationOther(e: RouterEvent): boolean {
    return (
      e instanceof NavigationEnd || e instanceof NavigationCancel || e instanceof NavigationError
    );
  }
}
