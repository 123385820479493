import { CanActivateFn, Router } from '@angular/router';
import { inject } from '@angular/core';
import { StorageService } from '../services/storage.service';
import { UserStore } from '../stores/user.store';

export const AuthGuard: CanActivateFn = async route => {
  const userStore = inject(UserStore);
  const router = inject(Router);
  const storage = inject(StorageService);
  const apiKey = route.queryParamMap.get('ApiKey') || route.queryParamMap.get('apiKey');

  if (apiKey) {
    storage.setItem('access_token', apiKey.replace(/\s/g, '+'));
    const query = route.queryParams;

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { apiKey: _, ApiKey: __, ...queryParams } = query;
    const queryString = new URLSearchParams(Object.entries(queryParams));
    let url = window.location.pathname;
    if (queryString.toString()) {
      url += '?' + queryString.toString();
    }

    return router.parseUrl(url);
  }

  const accessToken = storage.getItem('access_token');
  if (accessToken && !userStore.user()) {
    await userStore.loadUser(true);
  }
  if (userStore.user()) {
    return router.parseUrl('/');
  }

  return true;
};
