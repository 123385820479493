import { Component, inject, OnInit, signal, ViewChild, ViewContainerRef } from '@angular/core';
import {
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
  RouterEvent,
  RouterOutlet,
} from '@angular/router';
import { HeaderComponent } from './core/components/ui/header/header.component';
import { FooterComponent } from './core/components/ui/footer/footer.component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter } from 'rxjs';
import { LoadingComponent } from './core/components/ui/loading/loading.component';
import { LoadingLogoComponent } from './core/components/ui/loading-logo/loading-logo.component';
import { SpriteSvgComponent } from './core/components/ui/sprite-svg/sprite-svg.component';
import { DialogService } from './core/components/ui/dialog/services/dialog.service';
import { UtmTagsService } from './core/services/utm-tags-service';
import { AnalyticsService } from './core/services/analytics.service';
import { ENVIRONMENT } from './core/tokens/env.token';

@UntilDestroy()
@Component({
  selector: 'app-root',
  imports: [
    RouterOutlet,
    HeaderComponent,
    FooterComponent,
    LoadingComponent,
    LoadingLogoComponent,
    SpriteSvgComponent,
  ],
  templateUrl: './app.component.html',
  styles: ':host {display: flex; flex-direction: column; min-height: 100vh;}',
  standalone: true,
  providers: [],
})
export class AppComponent implements OnInit {
  private readonly router = inject(Router);
  private readonly dialogService = inject(DialogService);
  private readonly utmService = inject(UtmTagsService);
  private readonly analytics = inject(AnalyticsService);
  readonly env = inject(ENVIRONMENT);

  readonly loading = signal(true);
  readonly firstLoaded = signal(false);

  // readonly devZoneEnabled = inject(STORAGE_SIGNAL_FACTORY)('ST_dev_zone_enabled', false);
  // readonly isShowDevZone = computed(() => !this.env.production && this.devZoneEnabled());

  @ViewChild('dialogContainer', { read: ViewContainerRef, static: true })
  dialogContainerRef!: ViewContainerRef;

  ngOnInit() {
    this.dialogService.registerViewContainer(this.dialogContainerRef);

    this.router.events
      .pipe(
        filter(e => e instanceof RouterEvent),
        untilDestroyed(this)
      )
      .subscribe((event: RouterEvent) => {
        if (this.navigationStart(event)) {
          this.loading.set(true);
        }

        if (this.navigationEnd(event)) {
          this.analytics.pageView();
          this.utmService.initUtmTagsConversion();
        }

        if (this.navigationOther(event)) {
          this.loading.set(false);
          this.firstLoaded.set(true);
        }
      });
  }

  private navigationStart(e: RouterEvent): boolean {
    return e instanceof NavigationStart;
  }

  private navigationEnd(e: RouterEvent): boolean {
    return e instanceof NavigationEnd;
  }

  private navigationOther(e: RouterEvent): boolean {
    return (
      e instanceof NavigationEnd || e instanceof NavigationCancel || e instanceof NavigationError
    );
  }
}
