<header class="header">
  <div
    class="w-full macbook:max-w-[1142px] mx-auto px-4 flex items-center justify-center macbook:justify-start h-[78px] macbook:h-[110px]">
    <a
      href="/"
      class="w-36">
      <img
        src="https://d2bkxke45bupbc.cloudfront.net/assets/img/Group+(57).png"
        alt="" />
    </a>
  </div>
</header>
