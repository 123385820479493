<footer class="footer">
  <div
    class="w-full macbook:max-w-[1142px] mx-auto px-4 flex flex-col-reverse tablet:flex-row justify-between items-center gap-5 tablet:gap-1">
    <a
      class="flex flex-row tablet:flex-col macbook:flex-row items-center tablet:items-start macbook:items-center gap-5 tablet:gap-3 max-w-[190px] tablet:max-w-full"
      href="/"
      [title]="env.production ? 'production' : 'develop' + '(v' + env.version + ')'">
      <span class="icon-title w-[30px] tablet:w-[34px]"> </span>
      <span class="footer-title flex-none"> © {{ date | date: 'yyyy' }} STRETCHIT, Inc. </span>
    </a>

    <div class="hidden macbook:!flex flex-row flex-grow">
      <!--    desktop menu-->
      <ng-container [ngTemplateOutlet]="menu" />
    </div>
    <!--    desktop social-->
    <div class="hidden macbook:!block">
      <ng-container [ngTemplateOutlet]="social" />
    </div>

    <!--    mobile menu + social-->
    <div
      class="flex flex-col-reverse tablet:flex-col items-center tablet:items-end gap-5 macbook:hidden">
      <ng-container [ngTemplateOutlet]="social" />
      <ng-container [ngTemplateOutlet]="menu" />
    </div>
  </div>
</footer>

<ng-template #menu>
  <ul
    class="st-w-fill footer-menu flex items-center justify-center tablet:justify-end macbook:justify-center gap-5">
    @for (link of links; track link.label) {
      <li>
        <a
          [href]="link.href"
          [target]="link.target">
          {{ link.label }}
        </a>
      </li>
    }
  </ul>
</ng-template>

<ng-template #social>
  <div class="footer-social flex gap-2 items-center flex-none">
    <a
      href="https://www.instagram.com/stretchitapp"
      target="_blank"
      class="flex-none">
      <img
        src="/assets/images/icons/ig-round.svg"
        alt="" />
    </a>

    <a
      href="https://www.youtube.com/StretchItApp"
      target="_blank"
      class="flex-none">
      <img
        src="/assets/images/icons/youtube-round.svg"
        alt="" />
    </a>

    <a
      href="https://www.facebook.com/StretchItApp"
      target="_blank"
      class="flex-none">
      <img
        src="/assets/images/icons/fa-round.svg"
        alt="" />
    </a>

    <a
      href="https://www.tiktok.com/@stretchitapp"
      target="_blank"
      class="flex-none">
      <img
        src="/assets/images/icons/ticktok-round.svg"
        alt="" />
    </a>

    <a
      href="https://x.com/stretchitapp"
      target="_blank"
      class="flex-none">
      <img
        src="/assets/images/icons/x-round.svg"
        alt="" />
    </a>
  </div>
</ng-template>
