import { effect, inject, Injectable, signal } from '@angular/core';
import { Workshop, WorkshopApiService } from '../../api/workshop-api.service';
import { waitForSignal } from '../../shared/helpers/wait';
import { ResolveFn } from '@angular/router';
import { StorageService } from '../services/storage.service';
import dayjs from 'dayjs';
import { catchError, firstValueFrom, throwError } from 'rxjs';
import { AnalyticsService } from '../services/analytics.service';
import { ENVIRONMENT } from '../tokens/env.token';
import { isNotNullish, isNullish } from '../../shared/helpers/ts.helpers';
import { finalize } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class WorkshopStore {
  private readonly storage = inject(StorageService);
  private readonly workshopApi = inject(WorkshopApiService);
  private readonly analytics = inject(AnalyticsService);
  private env = inject(ENVIRONMENT);

  readonly workshop = signal<Workshop | null>(null);
  readonly isLoading = signal(false);
  readonly loadingError = signal<Error | null>(null);

  constructor() {
    const cachedWorkshop = this.storage.getItem('st_stored_workshop');
    if (cachedWorkshop) {
      try {
        const workshop = JSON.parse(cachedWorkshop) as Workshop;
        const token = this.storage.getItem('access_token');
        if (
          dayjs(workshop.start_date).toDate().getTime() > Date.now() + 48 * 60 * 60 * 1000 &&
          !token &&
          !workshop.registered
        ) {
          this.workshop.set(workshop);
          // this.mayBeOverrideStartDate();
          this.isLoading.set(false);
        }
      } catch (e) {
        console.log(e);
        this.storage.removeItem('st_stored_workshop');
      }
    }
    effect(() => {
      const workshop = this.workshop();
      if (isNotNullish(workshop)) {
        this.storage.setItem('st_stored_workshop', JSON.stringify(workshop));
      }
    });
  }

  setStartDate(date: string) {
    if (this.env.production || !this.workshop()) return;
    const start_date = dayjs(date)
      .add(dayjs().utcOffset() + 300, 'minutes')
      .toISOString();

    if (start_date != this.workshop()?.start_date) {
      this.workshop.update(w => {
        return isNullish(w)
          ? w
          : {
              ...w,
              start_date,
            };
      });
    }
  }

  async loadWorkshop(): Promise<Workshop | null> {
    this.load();
    if (this.workshop()) {
      return this.workshop();
    }
    await waitForSignal(this.isLoading, false);
    if (this.loadingError()) throw this.loadingError();
    return this.workshop();
  }

  async register(in120sec = false) {
    const workshop = this.workshop() ?? (await this.loadWorkshop());
    if (workshop) {
      const success = await firstValueFrom(
        this.workshopApi.registerWorkshop(workshop.id, in120sec)
      );
      if (success) {
        this.analytics.lead();
        this.workshop.update(w => (w ? { ...w, registered: true } : null));

        // this.mayBeOverrideStartDate();
      }
    }
  }

  private load() {
    this.isLoading.set(true);
    this.loadingError.set(null);
    this.workshopApi
      .getWorkshop()
      .pipe(
        finalize(() => this.isLoading.set(false)),
        catchError(e => {
          this.loadingError.set(e);
          return throwError(() => e);
        })
      )
      .subscribe(workshop => {
        this.workshop.set(workshop);
        // this.mayBeOverrideStartDate();
      });
  }

  // private mayBeOverrideStartDate() {
  //   const overrideStartDate = this.storage.getItem('OVERRIDE_workshop_start_date');
  //   if (overrideStartDate) {
  //     this.setStartDate(overrideStartDate);
  //   }
  // }
}

export const WorkshopResolver: ResolveFn<Workshop | null> = () =>
  inject(WorkshopStore).loadWorkshop();
