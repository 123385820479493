import { version } from './version';
import { IEnvironment } from '../app/core/types/IEnvironment';

export const environment: IEnvironment = {
  assets: 'https://d2bkxke45bupbc.cloudfront.net/workshop/develop/assets',
  version,
  production: false,
  apiBaseUrl: 'https://api-m.stretchitlive.tech',
  apiOldBaseUrl: 'https://api-stage.stretchitapp.com',
  webapp: 'https://stage-web.stretchitapp.com',
  workshop: 'https://workshop.stretchitapp.tech',
  facebookAppId: '841146102608637',
  googleKey: '617438587450-991r5ks34au2m3d8m6lvpbjs17t3tp32.apps.googleusercontent.com',
  workshopDuration: 1, // in hours
  recordDuration: 48, // in hours
  specialOfferShowInMinutes: 45, // in minutes
} as const;
