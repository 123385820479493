import {
  HttpErrorResponse,
  HttpEvent,
  HttpHeaders,
  HttpInterceptorFn,
  HttpRequest,
} from '@angular/common/http';
import { inject } from '@angular/core';
import { StorageService } from '../services/storage.service';
import { version } from '../../../environments/version';
import dayjs from 'dayjs';
import { catchError, Observable, throwError } from 'rxjs';
import { ClearUserService } from '../services/clear-user.service';

type Headers = { [key: string]: string };

function throwErr(message: string, code?: string): Observable<never> {
  return throwError(() => {
    const error = new Error(message);
    error.name = code || 'BackendError';
    return error;
  });
}

export const authInterceptor: HttpInterceptorFn = (req, next) => {
  const storage = inject(StorageService);
  const clearUserService = inject(ClearUserService);

  function isGoogleApiRequest(req: HttpRequest<unknown>): boolean {
    return req?.url.includes('googleapis.com');
  }

  function isGeoApiRequest(req: HttpRequest<unknown>): boolean {
    return req?.url.includes('ipapi.co');
  }

  function isStripeRequest(req: HttpRequest<unknown>): boolean {
    return req?.url.includes('api.stripe');
  }

  const headers: Headers = {};
  const accessToken = storage.getItem('access_token');

  if (!isGoogleApiRequest(req) && !isGeoApiRequest(req) && !isStripeRequest(req)) {
    const attributes = {
      _fbc: storage.getItem('_fbc') || undefined,
      _fbp: storage.getItem('_fbp') || undefined,
      _ga: storage.getItem('_ga') || undefined,
      _gid: storage.getItem('_gid') || undefined,
      _ttp: storage.getItem('_ttp') || undefined,
    };

    headers['x-app-version'] = `web:${version}`;
    headers['x-attribute'] = JSON.stringify(attributes);

    const tz = dayjs.tz.guess();
    if (tz) {
      headers['x-time-zone'] = tz;
    }
  }

  if (accessToken && !isGoogleApiRequest(req) && !isStripeRequest(req)) {
    headers['x-token'] = `ApiKey="${accessToken.replace(/\s/g, '+')}"`;
    headers['X-Frame-Options'] = 'SAMEORIGIN';
  }

  req = req.clone({
    headers: new HttpHeaders(headers),
  });

  return next(req).pipe(
    catchError((err: HttpErrorResponse): Observable<HttpEvent<unknown>> => {
      if (err.error) {
        const error = err.error;

        if (error.message) {
          return throwErr(error.message, String(err.status));
        }

        if (error.error_description) {
          if (error.error === 'invalid_grant' && err.status === 403) {
            storage.clear();
            clearUserService.clear();
          }
          return throwErr(error.error_description, String(err.status));
        }

        if (error.error) {
          return throwErr(error.error);
        }
      }

      console.log(err);

      return throwErr('Connection error. Please try again later.');
    })
  );
};
